import '../styles/global.scss'
import '../styles/reset.scss'
import '../components/header/headerAppyShop.scss'
import '../components/footer/footerAppyShop.scss'
import { appWithTranslation } from 'next-i18next'
import nextI18NextConfig from '../next-i18next.config.js'
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from '../store/configureStore'
import { ToastContainer } from 'react-toastify'
import Head from "next/head";


const MyApp = ({ Component, pageProps }) => {
    return process.browser ? (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Head>
                    <script defer data-domain="rollertoothbrush.com"
                            src="https://plausible.wm-dev.fr/js/script.js"></script>
                    <script
                        dangerouslySetInnerHTML={{
                            __html: `
                            !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '2113257905761759');
                        fbq('track', 'PageView');
                       `
                        }}
                    />
                    <noscript><img height="1" width="1" style="display:none"
                                   src="https://www.facebook.com/tr?id=2113257905761759&ev=PageView&noscript=1"
                    /></noscript>
                    <script
                        dangerouslySetInnerHTML={{__html : `
                            const typebotInitScript = document.createElement("script");
                            typebotInitScript.type = "module";
                            typebotInitScript.innerHTML = \`import Typebot from 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.3/dist/web.js'
                                  const showOverlayAndLaunch = () => {
                                  const overlay = document.createElement('div');
                                  overlay.id = 'typebot-overlay';
                                  overlay.style.position = 'fixed';
                                  overlay.style.top = 0;
                                  overlay.style.left = 0;
                                  overlay.style.width = '100vw';
                                  overlay.style.height = '100vh';
                                  overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                                  overlay.style.zIndex = 9998;
                                  document.body.appendChild(overlay);
                        
                                  Typebot.initPopup({ 
                                    typebot: "lead-magnet-rtb", 
                                    autoShowDelay: 10,
                                    onClose: () => {
                                        document.body.removeChild(overlay);
                                    } 
                                  });
                        
                                  window.addEventListener('typebotClose', () => {
                                    document.body.removeChild(overlay);
                                  });
                                };

                                if(window?.location?.pathname === '/newsletter') {
                                    Typebot.initStandard({ typebot: "lead-magnet-rtb" });
                                } else {
                                     setTimeout(() => {
                                      showOverlayAndLaunch();
                                    }, 6000);
                                }                                
                            \`;
                            document.body.append(typebotInitScript);
                        `}}
                    ></script>
                </Head>
                <ToastContainer/>
                <Component {...pageProps} />
            </PersistGate>
        </Provider>
    ) : (
        <Provider store={store}>
            <ToastContainer/>
            <Component {...pageProps} />
        </Provider>
    )
}


export default appWithTranslation(MyApp, nextI18NextConfig)